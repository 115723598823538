import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import ToyboxBeatingHeart from '../../components/integrations/ToyboxBeatingHeart';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    max-width: 54rem;
    flex-wrap: wrap;
    margin: 0 auto 5rem;
    padding: 0 1rem;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    text-align: center;
    width: 24rem;
    & svg {
      border-radius: 100px;
      background: #107db522;
      padding: 10px;
      color: #107db5;
    }
  }

  .buttonContainer {
    text-align: center;
  }
  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 2rem;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin: 2rem auto;

    & span {
      position: relative;
      z-index: 1;
    }
    & svg {
      vertical-align: text-bottom;
      margin-left: 0.5rem;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Toybox Systems integration" keywords={['']} pathname={this.props.location.pathname} />

        <PageHeader>
          <ToyboxBeatingHeart />
          <h1>Report issues to Toybox directly from inside Polypane</h1>
          <Link to="/integrations/">See all integrations</Link>
        </PageHeader>

        <div className={styles.items}>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-photo"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="15" y1="8" x2="15.01" y2="8" />
              <rect x="4" y="4" width="16" height="16" rx="3" />
              <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
              <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
            </svg>
            <h2>Capture screenshots</h2>
            <p>Capture screenshots inside Polypane and send them to Toybox with all data.</p>
          </div>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-users"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="9" cy="7" r="4" />
              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
              <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
            </svg>
            <h2>Collaborate with your team</h2>
            <p>Add notes and comments to your page together with your team.</p>
          </div>
        </div>
        <div className="page childPadding">
          <p className={styles.buttonContainer}>
            Unfortunately, Toybox Systems has shut down and upgraded to{' '}
            <a href="https://www.miruni.io/?ref=polypane">Miruni</a>, which we do not yet have an integration for. If
            you'd like to see a Miruni integration, please <a href="/support/">let us know</a>!
          </p>
        </div>
        <div className="page childPadding">
          <p className={styles.buttonContainer}>
            <Link to="/integrations/">Back to integrations overview</Link>
          </p>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
